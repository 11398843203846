import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Game from './pages/Game';
import Level1 from './pages/level1/Level1';
import Level2 from './pages/level2/Level2';
import Level3 from './pages/level3/Level3';
import Level4 from './pages/level4/Level4';
import Level5 from './pages/level5/Level5';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/game" element={<Game/>} />
        <Route exact path="/game/level1" element={<Level1/>} />
        <Route exact path="/game/level2" element={<Level2/>} />
        <Route exact path="/game/level3" element={<Level3/>} />
        <Route exact path="/game/level4" element={<Level4/>} />
        <Route exact path="/game/level5" element={<Level5/>} />
      </Routes>
    </Router>
  );
}

export default App